<template>
  <div id="user" class="overlay">
    <div class="w-11/12 md:w-2/4 popup" v-if="!showConfirmation">
      <div class="flex justify-between items-center">
        <h5 class="text-xl font-semibold">REGISTER USER</h5>
        <a @click="changeShowConfirmation()" class="text-2xl font-bold" href="#"
          >&times;</a
        >
      </div>
      <hr />
      <RegisterUserForm @register-user-event="handleSubmit" />
      <p v-if="showError" class="text-center text-red-600">
        {{ errorMessage }}
      </p>
    </div>
    <div class="popup w-11/12 md:w-1/2" v-if="showConfirmation">
      <div class="flex justify-between items-center">
        <h5 class="text-base md:text-xl font-semibold">
          USER HAS BEEN SUCCESSFULLY ADDED
        </h5>
        <a @click="changeShowConfirmation()" class="text-2xl font-bold" href="#"
          >&times;</a
        >
      </div>
      <em
        class="block mx-auto mt-5 mb-14 text-9xl fa-solid fa-circle-check check-icon"
      ></em>
      <p class="mb-5 text-center">
        The new user will receive an email with a link containing a registration
        code.
      </p>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import {
  registrationEmail,
  createLocationCode,
} from "@/services/location/location.js";
import RegisterUserForm from "./RegisterUserForm.vue";

const showConfirmation = ref(false);
const showError = ref(false);
const errorMessage = ref("An error has ocurred, please try again!");

async function handleSubmit(data) {
  const codeResponse = await createLocationCode(data.location, data.role);
  const locationCode = codeResponse.data.location_code;

  if (!codeResponse.success) {
    showError.value = true;
  }

  try {
    await registrationEmail({
      email: data.email,
      code: locationCode,
      isLocationUser: data.locationUser,
    });

    showConfirmation.value = true;
  } catch (error) {
    showError.value = true;
    errorMessage.value = error;
  }
}

function changeShowConfirmation() {
  showConfirmation.value = false;
}
</script>

<style scoped>
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity 200ms;
  visibility: hidden;
  opacity: 0;
  z-index: 99;
}

.overlay:target {
  visibility: visible;
  opacity: 1;
}

.popup {
  margin: 75px auto;
  padding: 20px;
  background: #fff;
  border: 1px solid #666;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
  position: relative;
}

.check-icon {
  height: 87px;
  width: 87px;
}
</style>
