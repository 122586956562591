<template>
  <div class="w-full h-screen">
    <div class="flex w-full justify-between items-center text-white mt-2 p-5">
      <h2 class="text-white font-semibold text-xl">
        Manage Location Delegates Users
      </h2>
      <div>
        <a
          class="hidden md:inline text-center p-2 font-semibold bg-mindaro text-darkBlue"
          href="#user"
          >+ Add Delegate User</a
        >
      </div>
    </div>
    <RegisterUserForm />
    <LocationsTable
      :data="users"
      :labels="['Email', 'Name', 'Added', 'Profiles']"
      @changePage="loadDelegateUsers"
    />
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { getUsers } from "@/services/location/location.js";
import RegisterUserForm from "@/components/location/RegisterUserModal.vue";
import LocationsTable from "@/components/location/LocationAdminTable.vue";

const users = ref(null);

onMounted(async () => {
  await loadDelegateUsers()
});

async function loadDelegateUsers (page) {
  const data = await getUsers(page);
  users.value = data.data;
}
</script>
